.navLink{
  text-decoration: none;
  color: #7D7C96;
}
.activeNavLink{
color: #FD0D37;
text-decoration: underline  #FD0D37;
}
.topLogo{
  background: url('../../assets/svg/logo_echovox.svg') no-repeat 50%;
  background-size: 80%;
  background-position: center;
  height: 40px;;
}