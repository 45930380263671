
.logo{
    background: url('../assets/svg/logo_echovox.svg') no-repeat 50%;
    background-size: 40%;
    background-position: left;
    height: 100px;
}

.mainImage{
    background: url('../assets/svg/illustrationfull.svg') no-repeat 50%;
    background-size: 80%;
    background-position: center;
    height: 90%
}